@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&family=Playfair+Display&display=swap");
@import "./style/";

body {
	margin: 0;
	font-family: "Open Sans", sans-serif;
	color: #fff;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}