.app-container {
	height: 100vh;

	.content-page {
		height: calc(100vh - 52px - 34px);
		position: relative;
		top: 52px;
	}
}

.header {
	width: 100%;
	margin: 0;
	border: 0px;
	padding: 0px;
	box-shadow: none;
	min-height: 42px;
	background-color: #60bb46;
	border-bottom: 2px solid #d7ebd9;
	height: fit-content;

	&.navbar {
		display: flex;
		align-items: center;

		&.navbar-fixed-top {
			position: fixed;
			top: 0px;
			width: 100%;
			z-index: 10000;

		}

		.navbar-brand {
			display: inline-block;
			margin-top: -1px;
			margin-right: 0;
			float: left;
			font-size: 18px;
			line-height: 20px;
			background: white;
			text-align: center;
			height: -moz-fit-content;
			height: fit-content;
			padding: 5px;
			padding-bottom: 2px;

			img {
				height: 40px;
				margin: 1px 0 0 12px;
			}
		}

		.navbar-text {
			margin-left: 10px;
			font-size: 14px;
			font-weight: 700;
		}
	}
}




.footer {
	background-color: #60bb46;
	position: fixed;
	left: 0;
	right: 0;
	z-index: 10000;
	bottom: 0;
	display: flex;
	align-items: center;
	padding: 8px 20px 9px 20px;

	.footer-text {
		font-size: 12px;
	}
}

.error-container {
  color: #333;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .error-message {
    padding: 24px;
  }
}

.btn {
  padding: 12px 24px;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &.btn-primary {
    background-color: #60bb46;
    color: #fff;
  }
}